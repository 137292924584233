<template>
    <div v-if="error">
        <div class="alert alert-danger" role="alert">
            {{ error }}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { getAPI } from '../../utils/axios-api'
import { useRouter } from 'vue-router'

export default {
    name: "EmailVerify",
    props: ['query'],
    setup(props) {   
        const router = useRouter();
        let token = props.query.token
        let url = '/account/email-verify/?token='+ token    
        const error = ref(null)
        const isSucceed = ref(false)

        getAPI.get(url).then((response) => {  
            console.log(response)
            if (response.status === 200){
                isSucceed.value = true
                router.push({ name: "Login",query:{message:isSucceed}});
                error.value = ''                
            }  
        })
        .catch(err => {  
            console.log(err.response)
            error.value = err.response.data.message         
        })
        return { error}

    }
}
</script>

<style>

</style>